body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landingpage--container {
  margin-top: 0;

  height: 100vh;

  background-image: url('https://pareiovision-assets.s3.amazonaws.com/clayman.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.rotate {
  transform-origin: center;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

div.menu-button {
  display: block;
  transition: all 0.4s ease-in-out;
  line-height: 1.4;
}

.logo-image {
  position: relative;
  height: 5vh;
  display: flex;

  align-items: center;
  img {
    position: absolute;

    transition: transform 0.5s, box-shadow 0.5s;
  }
}

.hover-busy:hover {
  color: #efe8e0;
}
.bg-busy {
  background-color: #efe8e0;
}
